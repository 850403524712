.sensors-row-content {
    display: flex;
    column-gap: 0.25rem;
    justify-content: flex-start;
}

.tags-row-content {
    display: flex;
    column-gap: 0.25rem;
    justify-content: flex-start;
}

.tags-tooltip .arrow::before {
    border-top-color: white !important;
}

.tags-tooltip .tooltip-inner {
    background: white;
    box-shadow: 0rem 0.0625rem 0.1875rem rgba(0, 0, 0, 0.01), 0rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.06);
    max-width: 15.625rem;
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
    column-gap: 0.3125rem;
    row-gap: 0.3125rem;
}

.add-equipment-footer {
    display: flex;
    width: '100%';
    width: 100%;
    gap: 0.25rem;
    justify-content: space-between;
}

.modal-fullscreen {
    width: 92vw !important;
    max-width: none !important;
}
