@use '../../../assets/scss/colors' as colors;

.rotate-180 {
    transform: rotate(180deg);

    svg {
        transition: 0.4s all;
    }
}

.box-shadow {
    &-xl {
        box-shadow: 0 0.625rem 1.5rem -0.25rem rgba(16, 24, 40, 0.08), 0 0.375rem 0.5rem -0.25rem rgba(16, 24, 40, 0.03);
    }
    &-xs {
        box-shadow: 0 0.0625rem 0 rgba(16, 24, 40, 0.05);
    }
    &-md {
        box-shadow: 0 0.0625rem 0.1875rem rgba(16, 24, 40, 0.01), 0 0.0625rem 0.125rem rgba(16, 24, 40, 0.06);
    }
    &-lg {
        box-shadow: 0 0.25rem 0.375rem -0.0625rem rgba(16, 24, 40, 0.02),
            0 0.125rem 0.25rem -0.0625rem rgba(16, 24, 40, 0.06);
    }
    &-sm {
        box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.05);
    }
}

.borders {
    border: 0.0625rem solid colors.$primary-gray-200;

    &-top {
        border-top: 0.0625rem solid colors.$primary-gray-200;
    }

    &-right {
        border-bottom: 0.0625rem solid colors.$primary-gray-200;
    }

    &-bottom {
        border-bottom: 0.0625rem solid colors.$primary-gray-200;
    }

    &-left {
        border-bottom: 0.0625rem solid colors.$primary-gray-200;
    }

    &-base {
        @extend .borders;
        @extend .box-shadow-md;
        border-radius: 0.5rem;
    }
}

.vertical-line {
    height: 100%;
    width: 0.0625rem;
    background: colors.$primary-gray-200;
}

.horizontal-line {
    height: 100%;
    height: 0.0625rem;
    background: colors.$primary-gray-200;
}

.reset-styles {
    background: none;
    border: 0;
    box-shadow: none;
    margin: 0;
    padding: 0;
}

.square {
    border-radius: 0 !important;
}

$text-colors: (
    'error-600': colors.$error-600,
    'gray-550': colors.$primary-gray-550,
    'gray-800': colors.$primary-gray-800,
    'gray-900': colors.$primary-gray-900,
    'gray-950': colors.$primary-gray-950,
);

@each $color, $value in $text-colors {
    html body .#{$color} {
        color: $value !important;

        path {
            fill: $value;
        }
    }
}

//Skeleton loading
.skeleton {
    background: colors.$primary-gray-150;
    background: linear-gradient(110deg, colors.$primary-gray-100 8%, colors.$primary-gray-25 18%, colors.$primary-gray-150 33%);
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}
