@use '../../../assets/scss/colors' as colors;

.socket-container-style {
    padding: 1.25rem;
    width: 100%;
    height: fit-content;
    background: colors.$base-white;
    border: 0.125rem solid colors.$primary-gray-100;
    border-radius: 0.875rem;
}

.attached-device-socket {
    width: fit-content;
    height: 2.8125rem;
    background: colors.$base-white;
    border: 0.0625rem solid colors.$primary-gray-200;
    border-radius: 0.1875rem;
}

.active-socket {
    background: colors.$primary-indigo-100;
    border: 0.0625rem solid colors.$primary-indigo-600;
    border-radius: 0.1875rem;
}

.icon-style {
    width: fit-content;
    height: fit-content;
    padding: 0.25rem;
    background: colors.$base-white;
    border: 0.0625rem solid colors.$primary-gray-450;
    box-shadow: 0rem 0.0625rem 0rem rgba(16, 24, 40, 0.05);
    border-radius: 0.5rem;
}

.chart-icon-style {
    width: 0.8331rem;
    height: 0.7281rem;
}

.device-status-container {
    width: 8.75rem !important;
    height: 2rem !important;
    border-radius: 0.9375rem !important;
}

.completed-btn {
    background: colors.$success-50 !important;
    color: colors.$success-700 !important;
}

.failed-btn {
    background: colors.$dataviz-red-50 !important;
    color: colors.$dataviz-red-700 !important;
}

.found-btn {
    background: colors.$warning-50 !important;
    color: colors.$warning-700 !important;
}

.device-status-container {
    width: max-content !important;
    height: 1.75rem !important;
    border-radius: 1rem !important;
    padding: 0.5rem 1rem 0.5rem 1rem !important;
    gap: 0.5rem !important;
}

.device-online {
    background-color: colors.$success-600;
}

.device-offline {
    background-color: colors.$error-600;
}

.device-svg-style {
    path {
        fill: colors.$base-white !important;
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.clockwise-animation {
    animation: rotate 1s linear infinite; /* Adjust duration and timing function as needed */
}

.icon-color {
    path {
        fill: colors.$primary-gray-450 !important;
    }
}

.icon-color-active {
    path {
        fill: colors.$dataviz-blue-500 !important;
    }
}

.smartplug-device-container {
    padding: 1rem;
    border: 0.0625rem solid colors.$primary-gray-200;
    box-shadow: 0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.01), 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.06);
    border-radius: 0.5rem;
}
