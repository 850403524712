@use '../../../assets/scss/colors' as colors;
@use '../../assets/scss/helpers' as *;

.input-wrapper {
    .input-inner-wrapper {
        position: relative;
    }

    .input-icon {
        position: absolute;
        width: 1.09375rem;
        left: 0.625rem;
        top: 50%;
        transform: translateY(-50%);
    }
    .input-control {
        @extend .box-shadow-xs;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0.5rem;
        height: 2.25rem;
        border-color: colors.$primary-gray-450;
        font-family: 'Inter', serif;
        font-weight: 400;
        font-size: 0.875rem;
        color: colors.$primary-gray-800;
        border-radius: 0.375rem;

        &::placeholder {
            font-style: italic;
            font-weight: 300;
            font-size: 14px;
            color: colors.$primary-gray-450;
        }

        &:focus {
            border: 0.125rem solid colors.$dataviz-blue-600;
            box-shadow: 0 0.0625rem 0.125rem rgba(16, 24, 40, 0.05), 0 0 0 0.25rem colors.$dataviz-blue-100;
            // letter-spacing: 0.009375rem; // Commented style due to issue reported by user with ticket: PLT-1980
            color: colors.$primary-gray-800;
        }
    }

    .input-as-textearea {
        resize: none;
        height: unset;
        padding: 0.5rem 0.75rem 0.5rem 0.5rem;
    }

    &.icon {
        .input-control {
            padding-left: 2.375rem;
        }
    }

    &.element-start,
    &.element-end,
    &.error {
        .input-control {
            padding-right: 2.25rem;
        }
        .element-end-node {
            position: absolute;
            width: 1.25rem;
            right: 0.625rem;
            top: 50%;
            transform: translateY(-50%);

            svg {
                //width: 100%;
                //max-height: 100%;
            }
        }
        .element-start-node {
            position: absolute;
            width: 1.25rem;
            left: 0.625rem;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &.error {
        .input-control {
            border: 0.0625rem solid colors.$error-300;
        }
    }

    .input-tooltip {
        &-button {
            padding: 0;
            margin: 0;
            border: 0;
            background: none;
            position: absolute;
            width: 1.25rem;
            right: 0.625rem;
            top: 50%;
            transform: translateY(-50%);
        }

        &-icon {
        }
    }

    .input-error-label {
        color: colors.$error-700 !important;
    }

    &.disabled {
        .form-control {
            @extend .box-shadow-xs;
            background: colors.$primary-gray-50;
            border-color: colors.$primary-gray-250;
            color: colors.$primary-gray-450;
        }
    }

    & .input-type-file {
        padding: 0.4rem 0 0 0.625rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: colors.$primary-gray-350;
        font-size: 0.875rem;
        font-weight: 500;

        &::file-selector-button {
            display: none;
        }

        &:hover,
        &:focus {
            cursor: pointer;
            color: colors.$primary-gray-350;
            font-size: 0.875rem;
            font-weight: 500;
        }
    }
}
