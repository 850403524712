.layout-level-column {
    border-right: 0.0625rem solid $primary-gray-200;

    &-children {
        min-height: 60vh;
        overflow: auto;
    }

    &-no-items {
        text-align: center;
        padding-top: 10px;
    }
}
