@use '../../../assets/scss/colors' as *;

.input-wrapper {
    .input-inner-wrapper {
        position: relative;
    }

    .input-icon {
        position: absolute;
        width: 1.09375rem;
        left: 0.625rem;
        top: 50%;
        transform: translateY(-50%);
    }
    .textarea-control {
        padding-top: 0;
        padding-bottom: 0;
        height: auto;
        border-color: $primary-gray-250;
        box-shadow: 0 0.0625rem 0 0 #1018280d;
        font-family: 'Inter';
        font-weight: 300;
        font-size: 0.875rem;
        color: $primary-gray-500;
        border-radius: 0.375rem;
    }

    &.icon {
        .input-control {
            padding-left: 2.375rem;
        }
    }

    &.element-end,
    &.error {
        .input-control {
            padding-right: 2.25rem;
        }
        .element-end-node {
            position: absolute;
            width: 1.25rem;
            right: 0.625rem;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &.error {
        .input-control {
            border: 0.0625rem solid $error-300;
        }
    }

    .input-tooltip {
        &-button {
            padding: 0;
            margin: 0;
            border: 0;
            background: none;
            position: absolute;
            width: 1.25rem;
            right: 0.625rem;
            top: 50%;
            transform: translateY(-50%);
        }

        &-icon {
        }
    }

    .input-error-label {
        color: $error-700 !important;
    }
}
