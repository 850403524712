@import '../../assets/scss/colors';
@import '../assets/scss/helpers';

.tabs {
    &-wrapper {
        border-radius: 0.5rem;
        overflow: hidden;

        &.no-radius-left {
            .tab-content {
                border-top-left-radius: 0;
            }
        }

        &.large {
            .nav-tabs .nav-link {
                margin-bottom: -0.125rem;
                padding: 0.8rem 1rem 1.3rem 1rem;
                border-top-left-radius: 0.5rem;
                border-top-right-radius: 0.5rem;

                &.active {
                    border-color: $primary-gray-200;
                    border-bottom: 0;
                }

                &:hover:not(.active) {
                    border-color: transparent;
                }
            }
        }

        .tab-content {
            border: 0.0625rem solid $primary-gray-200;
            border-radius: 0.5rem;
        }

        .nav-tabs {
            border: 0;
        }

        &.subsection {
            .nav-tabs {
                padding: 0.25rem;
                background: $primary-gray-200;
                border-radius: 0.375rem 0.375rem 0 0;
                gap: 0.5rem;

                .nav-link {
                    border: 0.0625rem solid transparent;
                    border-radius: 0.375rem;
                    padding: 0.4375rem 0.6875rem;
                    margin: 0;

                    &:hover {
                        background: #f2f4f7;
                    }

                    &.active {
                        @extend .box-shadow-sm;
                        background: $base-white;
                        color: $primary-indigo-600 !important;

                        &:hover {
                            border: 0.0625rem solid $primary-indigo-300;
                            color: $primary-indigo-700 !important;
                        }
                    }
                }
            }

            .tab-content {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }
    }
}
