.single-passive-container {
    width: auto;
    height: 6.25rem;
    background: #f9fafb;
    border-bottom: 0.0625rem solid #eaecf0;
}

.nav-header-container {
    padding: 0rem;
    width: auto;
    border-bottom: 0.0625rem solid #eaecf0;
}
.single-consumption-container {
    padding: 16px;
    width: 100%;
    background: #f9fafb;
    border-radius: 10px;
}

.equipment-alert-container {
    padding: 16px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #eaecf0;
}

.passive-device-style {
    width: 88px;
    height: 18px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    /* line-height: 18px; */
    color: #667085;
}

.passive-device-name {
    width: 89px;
    height: 28px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    /* line-height: 28px; */
    color: #1d2939;
}

.passive-sensor-count {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    /* line-height: 18px; */
    color: #1d2939;
}

.passive-cancel-style {
    background: #ffffff !important;
    border: 1px solid #d9dde2 !important;
    box-shadow: 0px 1px 0px rgba(16, 24, 40, 0.05) !important;
    border-radius: 6px !important;
    /* Text md/Semibold */
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    /* line-height: 20px !important; */
    color: black !important;
}

.passive-save-style {
    background: #2955e7 !important;
    border: 1px solid #2142d4 !important;
    box-shadow: 0px 1px 0px rgba(16, 24, 40, 0.05) !important;
    border-radius: 6px !important;
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    /* line-height: 20px !important; */
    color: #ffffff !important;
}

.passive-page-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: stretch;
}

/* Add a black background color to the top navigation */
.topnav {
    background-color: #333;
    overflow: hidden;
}

/* Style the links inside the navigation bar */
.topnav a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    border-bottom: 3px solid transparent;
}

.topnav a:hover {
    border-bottom: 3px solid red;
}

.topnav a.active {
    border-bottom: 3px solid red;
}

.single-passive-tab {
    width: 70px;
    height: 22px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    /* line-height: 22px; */
    color: #344054;
}

.single-passive-tab-active {
    border-bottom: 2px solid rgb(80, 92, 255);
    padding-bottom: 3px;
    width: 80px;
    height: 22px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    /* line-height: 22px; */
    color: #3c6df5;
}

.single-passive-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em !important;
}

.container {
    margin: 25px !important;
}

.device-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    /* line-height: 24px; */
    color: #1d2939;
}

.device-label-style {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    /* line-height: 18px; */
    color: #1d2939;
}

.device-sub-label-style {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    /* line-height: 18px; */
    color: #667085;
}

.passive-location-style {
    padding: 0px 8px 0px 6px;
    width: 100%;
    height: 34px !important;
    background: #ffffff !important;
    border: 1px solid #eaecf0;
    box-shadow: 0px 1px 0px rgba(16, 24, 40, 0.05) !important;
    border-radius: 4px !important;
}

.passive-device-value {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    /* line-height: 20px; */
    text-align: left;
    color: #1d2939;
}

.search-container {
    padding: 7px 10px;
    width: 224px;
    height: 34px;
    background: #ffffff;
    border: 1px solid #d9dde2;
    box-shadow: 0px 1px 0px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
}

.search-box {
    width: 176px;
    height: 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* line-height: 20px; */
    color: #667085;
    border: none;
}

.sensor-container-style {
    padding: 10px 12px;
    width: 95%;
    height: 44px;
    background: #ffffff;
    border: 1px solid #eaecf0;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.01), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: stretch;
}

.sensor-container-style-notAttached {
    padding: 10px 12px;
    width: 95%;
    height: 44px;
    background: #f2f3f5;
    border: 1px solid #eaecf0;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.01), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: stretch;
}

.sensor-data-style {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: stretch;
    gap: 1.5em;
}

.sensor-data-style-right {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: stretch;
    gap: 0.7em;
}

.passive-edit-style {
    width: auto;
    height: 24px !important;
    background: #ffffff !important;
    border: 1px solid #d9dde2 !important;
    box-shadow: 0px 1px 0px rgba(16, 24, 40, 0.05) !important;
    border-radius: 4px !important;
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    color: #344054 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    padding: 3px 10px !important;
}

.sensor-data-no {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    /* line-height: 24px; */
    display: flex;
    align-items: center;
    color: #98a2b3;
}

.sensor-data-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    /* line-height: 20px; */
    display: flex;
    align-items: center;
    color: #2f3949;
}

.sensor-data-device {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    /* line-height: 18px; */
    display: flex;
    align-items: center;
    color: #1a53d7;
}

.model-sensor-date-time {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    /* line-height: 18px; */
    color: #667085;
}

.model-equip-name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    /* line-height: 18px; */
    color: #1d2939;
}

.sensor-chart-options {
    background: #ffffff;
    border: 1px solid #d9dde2;
    box-sizing: border-box;
    box-shadow: 0px 1px 0px rgba(16, 24, 40, 0.05);
    border-radius: 3px;
    padding: 7px;
}

.model-sensor-filters {
    padding: 25px;
    padding-top: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: left;
    align-items: stretch;
    align-content: stretch;
    width: 50%;
}

.model-sensor-energy-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 10px;
    width: 142px;
    height: 34px;
    background: #ffffff;
    border: 1px solid #d9dde2;
    box-shadow: 0px 1px 0px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
}

.model-sensor-date-range {
    width: 149px !important;
    height: 38px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    padding: 7px 10px !important;
    background: #ffffff;
    border: 1px solid #d9dde2;
    box-shadow: 0px 1px 0px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
}

.sensor-chart-options {
    width: 34px;
    height: 38px;
    background: #ffffff;
    border: 1px solid #d9dde2;
    box-shadow: 0px 1px 0px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px;
}

.select-breaker-style {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 4px 12px !important;
    gap: 8px !important;
    width: 100% !important;
    height: 37px !important;
    background: #ffffff !important;
    border: 1px solid #d9dde2 !important;
    border-radius: 6px !important;

    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #344054 !important;
}

.selected-option-style {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: stretch;
}

.checkmark:before {
    content: 'L' !important;
    font-family: arial !important;
    -ms-transform: scaleX(-1) rotate(-35deg) !important; /* IE 9 */
    -webkit-transform: scaleX(-1) rotate(-35deg) !important; /* Chrome, Safari, Opera */
    transform: scaleX(-1) rotate(-35deg) !important;
    display: inline-block !important;
    vertical-align: top !important;
    line-height: 1em !important;
    width: 1em !important;
    color: green !important;
    height: 1em !important;
    margin-right: 0.3em !important;
    text-align: center !important;
    position: absolute !important;
    right: 0 !important;
}

.breaker-select-style {
    width: 50px !important;
    height: 50px !important;
}

.search-box {
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 500 !important;
}
