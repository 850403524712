@use '../../assets/scss/colors' as colors;
@use '../assets/scss/helpers' as helper;

.range-slider {
    &-track-wrapper {
        height: 1.5rem;
        display: flex;
        width: calc(100% - 1.5rem);
        margin: auto;
    }
    &-track-line {
        height: 0.5rem;
        width: 100%;
        border-radius: 0.25rem;
        align-self: center;
    }

    &-thumb-wrapper {
        @extend .box-shadow-md;
        width: 1.5rem;
        height: 1.5rem;
        background: colors.$base-white;
        border: 0.0625rem solid colors.$dataviz-blue-600;
        border-radius: 100%;
        outline: none;
    }

    &-thumb-value {
        top: 2.125rem;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    &-controls {
        display: flex;
        justify-content: center;
        gap: 0.5rem;
        margin-bottom: 2rem;
        align-items: center;

        &-separator {
            background: colors.$primary-gray-800;
            height: 0.0938rem;
            width: 0.875rem;
        }
        &-input {
            width: 6.875rem;
        }
    }

    &-wrapper {
        @extend .borders;
        @extend .box-shadow-xs;

        border-radius: 0.375rem;
        padding: 1.5rem;
        display: inline-block;

        .no-filtered-data-alert-text {
            max-width: 8.125rem;
        }

        .drop-down-button-menu {
            width: auto;
            overflow: auto;
        }

        .button-group-wrapper {
            ul {
                padding: 0;
                margin: 0;
                width: 100%;
                li {
                    flex: 1 0;
                    justify-content: center;
                    svg {
                        width: 1.25rem;
                        height: 1.125rem;
                    }
                    &:not(.active) svg path {
                        fill: colors.$primary-gray-450;
                    }
                }
            }
        }
    }
}
