@use '../../../assets/scss/colors' as colors;

.edit-panel-custom-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    gap: 1rem;
}

.txt-warn-color {
    color: colors.$error-500 !important;
}
