@use '../../../assets/scss/colors' as colors;
@use '../../assets/scss/helpers' as helpers;

.radio {
    
    appearance: none;
    color-adjust: exact;
    print-color-adjust: exact;
    width: 1.25rem;
    height: 1.25rem;
    background: colors.$base-white;
    border: 0.0625rem solid colors.$primary-gray-450;
    border-radius: 0.625rem;
    cursor: pointer;

    display: grid;
    place-content: center;

    &-wrapper {
        label {
            margin: 0;
            display: flex;
            gap: 0.75rem;
            cursor: pointer;
        }
    }


    &:focus {
        box-shadow: 0rem 0rem 0rem 0.25rem colors.$dataviz-blue-100;
    }
    &:checked {
        background: colors.$primary-indigo-50;
        border: 0.0625rem solid colors.$primary-indigo-600;
    }
    &:checked::before {
        content: '';
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background: colors.$primary-indigo-600;
        display: block;
    }

    &:hover {
        background: colors.$primary-indigo-100;
    }

    &:disabled {
        background: colors.$primary-indigo-100;
        border: 0.0625rem solid colors.$primary-gray-250;

        &::before {
            background: colors.$primary-gray-250;
        }
    }
}
