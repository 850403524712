@use '../../../assets/scss/colors' as colors;

.device-detail-container {
    padding: 1rem;
    display: inline-block;
    border: 0.0625rem solid colors.$primary-gray-200;
    box-shadow: 0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.01), 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.06);
    border-radius: 0.5rem;
    width: 100%;
}

.device-detail-body {
    padding: 1rem;
    display: inline-block;
    border: 0.0625rem solid colors.$primary-gray-200;
    box-shadow: 0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.01), 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.06);
    border-radius: 0.5rem;
    width: 100%;
}

.utility-monitors-badge {
    color: colors.$primary-indigo-600 !important;
    background: colors.$primary-indigo-100 !important;
    border: 0.0625rem solid colors.$primary-indigo-50 !important;
}

.form-gap {
    gap: 1.5rem;
}

.chart-custom-loader {
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tooltip-button {
    padding: 0;
    background: none;
    outline: none;
    box-shadow: none;
    border: 0;
    margin: 0.25rem 0 0 0.25rem;
    cursor: pointer;
    height: 100%;

    svg {
        margin-top: -0.4375rem;
    }
}

.tooltip-icon {
    &:hover {
        path {
            fill: colors.$primary-gray-800;
        }
    }
}

.utility-sensor-modal-fullscreen {
    width: 92vw !important;
    max-width: none !important;
}

.utility-sensor-modal-fullscreen .modal-dialog {
    margin: 0 !important;
    max-width: none !important;
}

.utility-sensor-modal-fullscreen .modal-content {
    min-height: 92vh !important;
    border-radius: 0 !important;
}
