@use '../../assets/scss/colors' as colors;

.upper-content-container {
    padding: 0;
    background: none;
}

.lower-content-container {
    padding: 2rem 0;
}

.select-by {
    padding-bottom: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: stretch;
    align-content: stretch;
}

.select-by-type {
    min-width: 13rem;
}

.metadata-container {
    gap: 1rem;
    padding: 1.25rem;
    background: colors.$base-white;
    border: 0.0625rem solid colors.$primary-gray-200;
    box-shadow: 0rem 0.0625rem 0.1875rem rgba(16, 24, 40, 0.01), 0rem 0.0625rem 0.125rem rgba(16, 24, 40, 0.06);
    border-radius: 0.5rem;
}

.line-chart-loader {
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.space-tree-info {
    text-decoration: underline;
    display: flex;
    align-items: center;
}

.box-parent {
    border: 0.0625rem solid colors.$primary-gray-200;
    box-shadow: 0rem 0.0625rem 0.1875rem rgba(16, 24, 40, 0.01), 0rem 0.0625rem 0.125rem rgba(16, 24, 40, 0.06);
    border-radius: 0.625rem;
}

.box-parent-header {
    padding: 0.75rem 0 0.5rem 0;
    margin: 0 1rem;
    border-bottom: 0.0625rem solid colors.$primary-gray-200;
}

.box-parent-buttons {
    margin: 1rem;
    display: flex;
    justify-content: flex-end;
}

.box-parent-input {
    display: flex;
    margin: 0 1rem;
    padding: 1.25rem 0 1rem 0;
}

.spaces-breadcrumb:hover {
    cursor: pointer;
}

.modal-backdrop {
    opacity: 0.5;
    background-color: colors.$base-black;
}

.space-layout-container {
    max-width: none;
    height: auto;
    margin: 0;
    overflow-x: auto; /* Enable horizontal scrolling */
    max-width: 100%; /* Adjust as needed based on your layout */
}

/* Customize the scrollbar track */
.space-layout-container::-webkit-scrollbar {
    width: 0.525rem;
    height: 0.725rem; /* For horizontal scrollbar */
}

.space-layout-container::-webkit-scrollbar-track {
    background-color: colors.$success-25;
}

/* Customize the scrollbar thumb */
.space-layout-container::-webkit-scrollbar-thumb {
    background-color: colors.$primary-gray-400;
    border-radius: 0.3125rem;
}

/* On hover, customize the scrollbar thumb */
.space-layout-container::-webkit-scrollbar-thumb:hover {
    background-color: colors.$primary-gray-500;
}

/* On active, customize the scrollbar thumb */
.space-layout-container::-webkit-scrollbar-thumb:active {
    background-color: colors.$primary-gray-600;
}

.equip-name-column {
    margin: 0;
    color: #0000ee;

    :hover {
        cursor: pointer;
    }
}

.modal-layout-edit-space {
    width: 92vw !important;
    max-width: none !important;

    &-dialog {
        z-index: 1030 !important;
        margin: 0 !important;
        max-width: none !important;
    }

    &-backdrop {
        z-index: 1020 !important;
    }

    & .modal-content {
        min-height: 92vh !important;
        border-radius: 0 !important;
        padding: 2rem 2rem 0 2rem;
    }
}
