@use '../../assets/scss/colors' as colors;

.equip-type-socket {
    width: 100%;
    min-height: fit-content;
    background: colors.$primary-gray-50;
    border: 0.0625rem solid colors.$primary-gray-150;
    padding: 1.5rem;
}

.line-chart-loader {
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-fullscreen {
    width: 92vw !important;
    max-width: none !important;
}

.modal-fullscreen .modal-dialog {
    margin: 0 !important;
    max-width: none !important;
}

.modal-fullscreen .modal-content {
    min-height: 92vh !important;
    border-radius: 0 !important;
}

.chart-model-header {
    height: 6.75rem;
    width: 100%;
    padding: 2rem 2rem 0rem 2rem;
    background: colors.$primary-gray-50;
}

.metadata-container {
    gap: 1rem;
    padding: 1.25rem;
    background: colors.$base-white;
    border: 0.0625rem solid colors.$primary-gray-200;
    box-shadow: 0rem 0.0625rem 0.1875rem rgba(16, 24, 40, 0.01), 0rem 0.0625rem 0.125rem rgba(16, 24, 40, 0.06);
    border-radius: 0.5rem;
}

.notification-container {
    width: 18.75rem;
    position: absolute;
    bottom: 2rem;
    left: 1.5rem;
    z-index: 9999;
}

.smartplug-container {
    border: 0.0625rem solid colors.$primary-gray-200;
    box-shadow: 0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.01), 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.06);
    border-radius: 0.625rem;
}
