@use '../../assets/scss/colors' as colors;

.badge {
    &-wrapper {
        padding: 2px 10px;
        background: colors.$primary-gray-100;
        border-radius: 10px;
        display: inline-flex;
        text-align: center;
        align-items: center;
        color: colors.$primary-gray-550;
    }
}
