@use '../../assets/scss/colors' as colors;
@use '../assets/scss/helpers' as helpers;

.datepicker-wrapper {
    display: flex;
    align-items: center;

    font-size: 0.875rem;
    line-height: 1.25rem;
    color: colors.$primary-gray-700;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    border: 0.0625rem solid colors.$primary-gray-450;
    border-radius: 0.375rem;

    .datepicker-icon-btn {
        background: none;
        outline: none;
        border: 0;
        padding: 0;
        margin: 0;
        margin-right: 0.625rem;

        svg path {
            fill: colors.$primary-gray-450;
        }
    }

    .datepicker-custom-dates {
        padding: 0.4375rem 0;
    }

    .CalendarDay__blocked_out_of_range,
    .CalendarDay__blocked_out_of_range:active,
    .CalendarDay__blocked_out_of_range:hover {
        .typography-wrapper {
            color: colors.$primary-gray-450;
        }
    }

    .CalendarMonth_table {
        border-collapse: separate;
        border-spacing: 0 0.125rem;
    }

    .datepicker-day-content-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 3;
    }

    .CalendarDay__default {
        width: 2.25rem !important;
        height: 2.25rem !important;
        border: 0 !important;
        padding: 0 !important;

        &:hover {
            border-radius: 1.25rem;
            background: colors.$primary-gray-200;
        }
    }

    // start
    .CalendarDay__selected_start {
        position: relative;
        z-index: 12;

        &:not(.CalendarDay__selected_end) + .CalendarDay {
            position: relative;
            &::before {
                position: absolute;
                top: 0;
                left: -50%;
                bottom: 0;
                right: 0;
                content: ' ';
                background: colors.$primary-indigo-50;
                color: colors.$base-white;
            }
        }

        & + .CalendarDay__selected_end {
            position: relative;
            z-index: 1;
            &::after {
                position: absolute;
                top: 0;
                left: -50%;
                bottom: 0;
                right: 0;
                content: ' ';
                background: colors.$primary-indigo-50;
                color: colors.$base-white;

                border-top-right-radius: 1.25rem;
                border-bottom-right-radius: 1.25rem;
            }
        }
    }

    //end
    .CalendarDay {
        position: relative;

        & + .CalendarDay__selected_end {
            .datepicker-day-content-wrapper {
                background: colors.$primary-indigo-600;
                border-radius: 1.25rem;
                position: relative;
            }

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                content: ' ';
                background: colors.$primary-indigo-50;
                color: colors.$base-white;
                border-top-right-radius: 1.25rem;
                border-bottom-right-radius: 1.25rem;
            }
        }

        &.CalendarDay__selected_end.CalendarDay__selected_start {
            &::before,
            &::after {
                display: none;
            }
        }
    }

    // Will edit everything selected including everything between a range of dates
    .CalendarDay__selected_span {
        .datepicker-day-content-wrapper {
            background: colors.$primary-indigo-50;

            .typography-wrapper {
                color: colors.$primary-indigo-600;
            }
        }

        &:hover {
            .datepicker-day-content-wrapper {
                background: colors.$primary-indigo-100;
            }
        }
    }

    // Will edit selected date or the endpoints of a range of dates
    .CalendarDay__selected,
    .CalendarDay__selected_start {
        z-index: 2;
        .datepicker-day-content-wrapper {
            background: colors.$primary-indigo-600;
            border-radius: 1.25rem;
            position: relative;
            z-index: 2;
        }

        background: colors.$primary-indigo-600;
        color: colors.$base-white;
        border-radius: 1.25rem;
        .typography-wrapper {
            color: colors.$base-white;
        }

        &:hover {
            opacity: 0.9;
        }
    }

    // Will edit when hovered over. _span style also has this property
    .CalendarDay__selected:hover {
        color: colors.$base-white;
    }

    // Will edit when the second date (end date) in a range of dates
    // is not yet selected. Edits the dates between your mouse and said date
    .CalendarDay__hovered_span:hover,
    .CalendarDay__hovered_span {
        .datepicker-day-content-wrapper {
            background: colors.$primary-indigo-50;
        }
    }

    .CalendarMonth_caption {
        padding-top: 0.875rem;
        padding-bottom: 3.4375rem;
    }

    .Calendar--arrow-left {
        width: 0.8125rem;
        height: 0.8125rem;
        transform: rotate(180deg);
        position: absolute;
        left: 1.8125rem;
        top: 1.125rem;
        &:hover {
            path {
                fill: colors.$primary-gray-700;
            }
        }
    }
    .Calendar--arrow-right {
        width: 0.8125rem;
        height: 0.8125rem;
        position: absolute;
        right: 1.8125rem;
        top: 1.125rem;
        &:hover {
            path {
                fill: colors.$primary-gray-700;
            }
        }
    }

    .DayPicker_weekHeader {
        top: 4.0625rem;
        padding: 0 0.4375rem !important;
        & + .DayPicker_weekHeader {
            left: 17.8125rem !important;
        }
    }
    .DayPicker_weekHeader_li {
        max-width: 2.25rem;
    }
    .DayPicker {
        max-width: 35.5625rem;
        overflow: hidden;
        border-radius: 0.4375rem;

        & > div > div {
            max-width: 35.5625rem;
            overflow: hidden;
        }
    }

    .DateRangePicker_picker {
        @extend .box-shadow-lg;
    }

    .DateInput_fang {
        display: none;
    }
    .DayPicker_transitionContainer {
        max-width: 35.5625rem;
        overflow: hidden;

        &::before {
            content: ' ';
            display: block;
            width: 0.875rem;
            height: 0.0938rem;
            background: colors.$primary-gray-800;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 1.4375rem;
            z-index: 4;
            box-shadow: 0.125rem 0 0.8125rem 1.125rem rgb(255 255 255);
        }
    }

    .DayPicker_focusRegion {
        position: relative;
        &::before {
            content: ' ';
            display: block;
            width: 100%;
            height: 0.0625rem;
            background: colors.$primary-gray-200;
            position: absolute;
            top: 3rem;
            z-index: 2;
        }
        &::after {
            content: ' ';
            display: block;
            width: 0.0625rem;
            height: 100%;
            background: colors.$primary-gray-200;
            position: absolute;
            top: 3.0625rem;
            bottom: 0;

            left: 50%;
            transform: translateX(-50%);
            z-index: 2;

            box-shadow: 0 1.6875rem 0.25rem 0.4375rem rgb(255 255 255);
        }
    }

    .DayPickerKeyboardShortcuts_show__bottomRight::before {
        border-right-color: colors.$primary-indigo-600;
    }
    .DayPickerKeyboardShortcuts_panel {
        z-index: 3;
    }

    .DateInput_input {
        font-style: normal;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: colors.$primary-gray-800;

        width: 3.3125rem;
        display: block;
        padding: 0.4375rem 0;
    }
    .DateInput {
        width: auto;
    }

    .DateRangePickerInput {
        appearance: none;
        width: 0;
        height: 0;
        overflow: hidden;
    }

    .DateRangePicker_picker__directionLeft {
        top: 2rem !important;
        left: -0.7rem !important;
    }

    .DateInput {
        display: none;
    }
    .SingleDatePicker_picker__directionLeft {
        left: 0 !important;
        top: 30px !important;
    }
    .datepicker-calendar-bottom {
        @extend .borders-top;
        padding: 1rem;
        gap: 0.5rem;
    }
}

.timepicker {
    border: 0.5px solid grey; /* Border color */
    border-radius: 8px; /* Optional: Add rounded corners */
    padding: 0rem !important;
}

.time-input-select {
    font-weight: 500;
    padding: 0.375rem 0.75rem 0.375rem 0.625rem;
    border: 0;
    background: none;
    max-width: 11rem;
    text-overflow: ellipsis;
    overflow: hidden;
}

.anticon {
    filter: brightness(0.5);
}
