.single-passive-container {
    margin: 0rem !important;
    padding: 1rem;
    width: auto;
    height: 6.25rem;
    background: #f9fafb;
    border-bottom: 0.0625rem solid #eaecf0;
}
.sub-heading {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #000;
}
.email-head {
    color: blue;
    font-weight: 600;
}
.button-hide {
    background-color: white;
    border: none;
}
.my-modal {
    width: 300px !important;
    max-width: 300px !important;
}
.my-modal1 {
    width: 350px !important;
    max-width: 350px !important;
}
.passive-device-style {
    width: 88px;
    height: 18px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    /* line-height: 18px; */
    color: #667085;
}
.error-message {
    border: 1px solid #b42318;
    border-radius: 5px;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
}
.passive-device-name {
    width: 89px;
    height: 28px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    /* line-height: 28px; */
    color: #1d2939;
}

.passive-sensor-count {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    /* line-height: 18px; */
    color: #1d2939;
}

.passive-cancel-style {
    background: #ffffff !important;
    border: 1px solid #d9dde2 !important;
    box-shadow: 0px 1px 0px rgba(16, 24, 40, 0.05) !important;
    border-radius: 6px !important;
    /* Text md/Semibold */
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    /* line-height: 20px !important; */
    color: black !important;
}

.passive-save-style {
    background: #2955e7 !important;
    border: 1px solid #2142d4 !important;
    box-shadow: 0px 1px 0px rgba(16, 24, 40, 0.05) !important;
    border-radius: 6px !important;
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    /* line-height: 20px !important; */
    color: #ffffff !important;
}

.passive-page-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: stretch;
}

/* Add a black background color to the top navigation */
.topnav {
    background-color: #333;
    overflow: hidden;
}

/* Style the links inside the navigation bar */
.topnav a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    border-bottom: 3px solid transparent;
}

.topnav a:hover {
    border-bottom: 3px solid red;
}

.topnav a.active {
    border-bottom: 3px solid red;
}

.single-passive-tab {
    width: 70px;
    height: 22px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    /* line-height: 22px; */
    color: #344054;
}

.single-passive-tab-active {
    border-bottom: 2px solid rgb(80, 92, 255);
    padding-bottom: 3px;
    width: 80px;
    height: 22px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    /* line-height: 22px; */
    color: #3c6df5;
}

.single-passive-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em !important;
}

.container {
    margin: 25px !important;
}

.device-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    /* line-height: 24px; */
    color: #1d2939;
}

.device-label-style {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    /* line-height: 18px; */
    color: #1d2939;
}

.device-sub-label-style {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    /* line-height: 18px; */
    color: #667085;
}

.passive-location-style {
    padding: 0px 8px 0px 6px;
    width: 100%;
    height: 34px !important;
    background: #ffffff !important;
    border: 1px solid #eaecf0;
    box-shadow: 0px 1px 0px rgba(16, 24, 40, 0.05) !important;
    border-radius: 4px !important;
}

.passive-device-value {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    /* line-height: 20px; */
    text-align: left;
    color: #1d2939;
}

.search-container {
    padding: 7px 10px;
    width: 224px;
    height: 34px;
    background: #ffffff;
    border: 1px solid #d9dde2;
    box-shadow: 0px 1px 0px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
}

.search-box {
    width: 176px;
    height: 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* line-height: 20px; */
    color: #667085;
    border: none;
}

.sensor-container-style {
    padding: 10px 12px;
    width: 95%;
    height: 44px;
    background: #ffffff;
    border: 1px solid #eaecf0;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.01), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: stretch;
}

.sensor-container-style-notAttached {
    padding: 10px 12px;
    width: 95%;
    height: 44px;
    background: #f2f3f5;
    border: 1px solid #eaecf0;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.01), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: stretch;
}

.sensor-data-style {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: stretch;
    gap: 1.5em;
}

.sensor-data-style-right {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: stretch;
    gap: 0.7em;
}

.passive-edit-style {
    width: auto;
    height: 24px !important;
    background: #ffffff !important;
    border: 1px solid #d9dde2 !important;
    box-shadow: 0px 1px 0px rgba(16, 24, 40, 0.05) !important;
    border-radius: 4px !important;
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    color: #344054 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    padding: 3px 10px !important;
}

.sensor-data-no {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    /* line-height: 24px; */
    display: flex;
    align-items: center;
    color: #98a2b3;
}

.sensor-data-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    /* line-height: 20px; */
    display: flex;
    align-items: center;
    color: #2f3949;
}

.sensor-data-device {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    /* line-height: 18px; */
    display: flex;
    align-items: center;
    color: #1a53d7;
}

.model-sensor-date-time {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    /* line-height: 18px; */
    color: #667085;
}

.model-equip-name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    /* line-height: 18px; */
    color: #1d2939;
}

.sensor-chart-options {
    background: #ffffff;
    border: 1px solid #d9dde2;
    box-sizing: border-box;
    box-shadow: 0px 1px 0px rgba(16, 24, 40, 0.05);
    border-radius: 3px;
    padding: 7px;
}

.model-sensor-filters {
    padding: 25px;
    padding-top: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: left;
    align-items: stretch;
    align-content: stretch;
    width: 100%;
}

.model-sensor-filters-v2 {
    padding: 25px;
    padding-top: 0px;
    padding-bottom: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: right;
    align-items: stretch;
    align-content: stretch;
    width: 100%;
}

.model-sensor-energy-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 10px;
    width: 142px;
    height: 34px;
    background: #ffffff;
    border: 1px solid #d9dde2;
    box-shadow: 0px 1px 0px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
}

.model-sensor-date-range {
    width: 149px !important;
    height: 38px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    padding: 7px 10px !important;
    background: #ffffff;
    border: 1px solid #d9dde2;
    box-shadow: 0px 1px 0px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
}

.sensor-chart-options {
    width: 34px;
    height: 38px;
    background: #ffffff;
    border: 1px solid #d9dde2;
    box-shadow: 0px 1px 0px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px;
}

.active-sensor-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: stretch;
}

.socket-container {
    width: 75%;
    height: 30vh;
    background: #ffffff;
    border: 1px solid #f0f2f5;
    border-radius: 10px;
    margin-top: 2vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loader-design {
    display: inline-block !important;
}
.spinner {
    display: inline-block !important;
    border: 4px solid rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    border-top-color: white;
    opacity: 0;
    position: absolute;
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
    margin: auto;
    width: 20px;
    height: px;
    transition: opacity 250ms;
    animation: rotate-spinner 1s linear;
    animation-iteration-count: infinite;
}
.equip-socket-container {
    width: 100%;
    height: 30vh;
    background: #ffffff;
    border: 1px solid #f0f2f5;
    border-radius: 10px;
    margin-top: 2vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
}

.sockets-slots-container {
    width: 95%;
    height: 75%;
    background: #ffffff;
    border: 2px solid #f2f4f7;
    border-radius: 14px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: stretch;
    margin: 0px !important;
}

.socket-rect {
    background: #ffffff;
    border-radius: 3px;
    padding-top: 2px;
    padding-bottom: 2px;
    width: 75px;
    height: 75px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: stretch;
    align-content: stretch;
}

.online-socket-container {
    background: #ffffff;
    border: 2px solid #eaecf0;
    border-radius: 3px;
    width: 75px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px !important;
}

.union-icon-style {
    margin-top: 8vh;
}

.power-off-style {
    padding-bottom: 6px;
    width: 75px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.socket-plug1-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: stretch;
}

.online-socket-rect1 {
    position: absolute;
    width: 45px;
    height: 45px;
    /* left: 101.84px;
    top: 60px; */
    background: #c4c4c4;
    border-radius: 5px;
}

.online-socket-rect2 {
    position: absolute;
    width: 23px;
    height: 19px;
    left: 161px;
    top: 89px;
    background: #c4c4c4;
}

.online-socket-rect3 {
    position: absolute;
    width: 10px;
    height: 39px;
    left: 168px;
    top: 108px;
    background: #c4c4c4;
}

.socket-plug1 {
    width: 7px;
    height: 15px;
    background: #000000;
    border-radius: 1px;
}

.socket-plug2 {
    background: #000000;
    width: 12px;
    height: 15px;
}

.socket-plug2-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: stretch;
}

.page-button-style {
    /* background-color: red; */
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: stretch;
    padding-bottom: 10px;
}

.align-options-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.column-filter-styling {
    width: 100% !important;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #475467;
    margin-right: 2vw;
}
