@use '../../assets/scss/colors' as colors;
@use '../../assets/scss/custom/fonts/_fonts.scss' as fonts;

$text-colors: (
    'gray-450': colors.$primary-gray-450,
    'gray-500': colors.$primary-gray-500,
    'gray-550': colors.$primary-gray-550,
    'gray-600': colors.$primary-gray-600,
    'base-white': colors.$base-white,
    'base-black': colors.$base-black,
    'error-700': colors.$error-700,
);

.typography-wrapper {
    font-family: fonts.$main-font;

    &.header {
        &.xs {
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.5rem;
            color: colors.$primary-gray-800;
        }
        &.sm {
            font-weight: 500;
            font-size: 1.125rem;
            line-height: 1.625rem;
            color: colors.$primary-gray-800;
        }

        &.md {
            font-family: fonts.$second-font;
            font-weight: 700;
            font-size: 1.25rem;
            line-height: 1.75rem;
            color: colors.$primary-gray-800;
        }

        &.lg {
            font-family: fonts.$second-font;
            font-weight: 700;
            font-size: 1.5rem;
            line-height: 2rem;
            color: colors.$primary-gray-800;
        }
        &.xl {
            font-family: fonts.$second-font;
            font-weight: 700;
            font-size: 1.875rem;
            line-height: 2.375rem;
            color: colors.$primary-gray-800;
        }
        &.xxl {
            font-family: fonts.$second-font;
            font-weight: 500;
            font-size: 3rem;
            line-height: 3.75rem;
            color: colors.$primary-gray-900;
        }
    }

    &.subheader {
        &.md {
            font-weight: 600;
            font-style: normal;
            font-size: 0.875rem;
            line-height: 1.25rem;
            color: colors.$primary-gray-800;
        }

        &.sm {
            font-weight: 500;
            font-size: 0.75rem;
            line-height: 1.125rem;
            color: colors.$primary-gray-500;
            margin-top: 0;
        }
    }

    &.body {
        &.xxs {
            font-weight: 500;
            font-size: 0.625rem;
            line-height: 1rem;
            color: colors.$primary-gray-950;
        }

        &.xs {
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 1.125rem;
            color: colors.$primary-gray-550;
            margin-top: 0;
        }

        &.sm {
            font-style: normal;
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 1.125rem;
            color: colors.$primary-gray-800;
        }

        &.md {
            font-weight: 300;
            font-size: 0.875rem;
            line-height: 1.25rem;
            color: colors.$primary-gray-800;
        }

        &.lg {
            font-style: normal;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.25rem;
            color: colors.$primary-gray-800;
        }
    }

    &.link {
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.25rem;
        border-bottom: 0.0625rem solid;
        color: colors.$primary-indigo-600;
        text-decoration: none;
        display: inline-block;
    }

    &.Light {
        font-weight: 300 !important;
    }

    &.Regular {
        font-weight: 400 !important;
    }

    &.Medium {
        font-weight: 500 !important;
    }

    &.SemiBold {
        font-weight: 600 !important;
    }

    &.Bold {
        font-weight: 700 !important;
    }

    @each $color, $value in $text-colors {
        &.#{$color} {
            color: $value !important;
        }
    }
}
