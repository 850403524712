@use '../../../assets/scss/colors' as colors;
@use '../../../sharedComponents/assets/scss/helpers.scss' as *;

.device-container {
    padding: 1rem;
    display: inline-block;
    border: 0.0625rem solid colors.$primary-gray-200;
    box-shadow: 0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.01), 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.06);
    border-radius: 0.5rem;
    width: 100%;
    display: grid;
    grid-template-columns: 1.55fr 1.2fr 0.25fr;
}

.passive-header-wrapper {
    width: 100%;
    height: 6.75rem;
    background: colors.$primary-gray-50;
    border-bottom: 0.0625rem solid colors.$primary-gray-200;
    padding: 2rem 2rem 0rem 2rem;
}

.active-tab-style {
    border-bottom: 0.125rem solid colors.$dataviz-blue-500;
    color: colors.$dataviz-blue-500 !important;
    width: fit-content;
}

.passive-container {
    padding: 2rem 2rem 0rem 2rem;
}

.sensor-container {
    padding: 1rem;
    height: 2.75rem;
    background: colors.$base-white;
    border: 0.0625rem solid colors.$primary-gray-200;
    box-shadow: 0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.01), 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.06);
    border-radius: 0.25rem;
}

.sensor-unattach {
    background: colors.$primary-gray-50;
    border: 0.0625rem solid colors.$primary-gray-200;
}

.custom-input-field::-webkit-outer-spin-button,
.custom-input-field::-webkit-inner-spin-button {
    display: none;
}

.sensor-index {
    color: colors.$primary-gray-450 !important;
}

.sensor-equip {
    border-bottom: 0.125rem solid colors.$dataviz-blue-500;
    color: colors.$dataviz-blue-500 !important;
}

.sensor-badge-style {
    background: #eff5ff !important;
    color: #2142d4 !important;
}

.sensor {
    &-wrapper {
        @extend .box-shadow-xs;
        @extend .borders;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        border-radius: 0.25rem;
        transition: 0.35s all;
        overflow: hidden;

        &.partially-configured {
            background: colors.$warning-25;
            border-color: colors.$warning-700;
        }

        &.is-flagged {
            border: 0.125rem solid colors.$error-700;
        }
    }
}

.default-padding {
    padding: 1rem 2rem 2rem 2rem;
}

.modal-dialog-custom {
    max-width: none;
    height: auto;
    margin: 0;
    overflow-x: auto; /* Enable horizontal scrolling */
    max-width: 100%; /* Adjust as needed based on your layout */
}

/* Customize the scrollbar track */
.modal-dialog-custom::-webkit-scrollbar {
    width: 0.525rem;
    height: 0.725rem; /* For horizontal scrollbar */
}

.modal-dialog-custom::-webkit-scrollbar-track {
    background-color: colors.$success-25;
}

/* Customize the scrollbar thumb */
.modal-dialog-custom::-webkit-scrollbar-thumb {
    background-color: colors.$primary-gray-400;
    border-radius: 0.3125rem;
}

/* On hover, customize the scrollbar thumb */
.modal-dialog-custom::-webkit-scrollbar-thumb:hover {
    background-color: colors.$primary-gray-500;
}

/* On active, customize the scrollbar thumb */
.modal-dialog-custom::-webkit-scrollbar-thumb:active {
    background-color: colors.$primary-gray-600;
}
