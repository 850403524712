@use '../../../../assets/scss/colors' as colors;

.smartplug-socket {
    padding: 1rem !important;
    background-color: #f9fafb;
    height: 10rem;
}

.smartplug-header {
    padding: 1rem !important;
    height: max-content;
}

.smartplug-body {
    padding: 1.25rem 1rem 1rem 1rem !important;
    height: max-content;
}

.device-status-container {
    width: max-content !important;
    height: 1.75rem !important;
    border-radius: 1rem !important;
    padding: 0.5rem 1rem 0.5rem 1rem !important;
    gap: 0.5rem !important;
}

.device-online {
    background-color: colors.$success-600;
}

.device-offline {
    background-color: colors.$error-600;
}
