@use '../../../assets/scss/colors' as colors;

.form-check {
    display: block;
    min-height: 1rem;
    padding-left: 1.5rem;
    margin-bottom: 0;
}
.form-check .form-check-input {
    float: left;
    margin-left: -1.5rem;
}

.form-check-input {
    width: 1rem;
    height: 1rem;
    margin-top: 0.25rem;
    vertical-align: top;
    background-color: colors.$base-white;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 0.0625rem solid rgba(0, 0, 0, 0.25);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    print-color-adjust: exact;
}
.form-check-input[type='checkbox'] {
    border-radius: 0.25rem;
}
.form-check-input[type='radio'] {
    border-radius: 50%;
}
.form-check-input:active {
    filter: brightness(90%);
}
.form-check-input:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    border-width: 0.125rem !important;
}

.form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5;
}
.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
    cursor: default;
    opacity: 0.5;
}

.checkbox {
    &-description {
        color: colors.$primary-gray-550 !important;
    }
    &-wrapper {
        &.dropDownCheckbox {
            .form-check {
                align-items: center;
                margin-bottom: 0;
            }
        }
        .form-check-input:checked[type='checkbox'] {
            background-image: url(../../assets/icons/checked.svg);
        }
        .form-check-input {
            border-radius: 0.25rem;
            border: 0.0625rem solid colors.$primary-gray-450;

            &:checked {
                background-color: colors.$dataviz-blue-50;
                border-color: colors.$primary-indigo-600;
            }

            &:disabled {
                background-color: colors.$primary-gray-100;
                border: 0.0625rem solid colors.$primary-gray-200;
                opacity: 1;
                background-blend-mode: hard-light;
                filter: grayscale(1);
            }
            &[type='checkbox']:indeterminate {
                background-color: colors.$dataviz-blue-50;
                border-color: colors.$primary-indigo-600;
                background-image: url(../../assets/icons/mins.svg);
                background-size: 50%;
            }
        }

        .form-check-input:not(:disabled) {
            cursor: pointer;

            &:hover {
                background-color: colors.$dataviz-blue-100;
                border-color: colors.$primary-indigo-600;
            }
        }

        &.sm {
            .form-check-input {
                margin-right: 0.5rem;

                &:checked[type='checkbox'] {
                    background-size: 0.625rem;
                }
            }
        }

        &.md {
            .form-check-input {
                width: 1.25rem;
                height: 1.25rem;
                margin-right: 0.75rem;

                &:checked[type='checkbox'] {
                    background-size: 0.6875rem;
                }
            }
        }
    }
}
