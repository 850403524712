.breaker-linker-column {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 2.0625rem;
    height: 100%;
    justify-content: center;

    &-left {
        left: -0.25rem;
    }
    &-right {
        right: -0.25rem;
    }
}
