@use '../../../assets/scss/colors' as colors;

.weekday-container {
    padding: 0.1875rem 0.625rem;
    gap: 0.5rem;
    width: 3.75rem;
    height: 1.5rem;
    background: colors.$primary-indigo-50;
    border: 0.0625rem solid colors.$primary-indigo-50;
    border-radius: 0.25rem;
}

.time-selector-style {
    padding: 0.5rem 0.625rem;
    gap: 0.5rem;
    width: 5.9375rem;
    height: 2.25rem;
    background: colors.$base-white;
    border: 0.0625rem solid colors.$primary-gray-450;
    border-radius: 0.375rem;
    justify-content: center;
    align-items: center;
}
