.custom-card {
    background: #ffffff !important;
    border: 1px solid #eaecf0 !important;
    box-sizing: border-box !important;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05) !important;
    border-radius: 8px !important;
}

.button-wrapper {
    display: flex;
    flex-direction: row;
}

.icon-bg-pop-styling {
    align-items: center;
    padding: 2px 6px;
    gap: 3px;

    width: 100px;
    height: 25px;
    color: white;

    /* Success/500 */

    background: #12b76a;
    /* Success/600 */

    border: 1px solid #039855;
    border-radius: 24px;
}

.icon-bg-pop-styling-slash {
    align-items: center;
    padding: 2px 6px;
    gap: 3px;

    width: 100px;
    height: 25px;
    color: white;

    /* Success/500 */

    background: rgb(240, 28, 28);
    /* Success/600 */

    border: 1px solid rgb(240, 28, 28);
    border-radius: 24px;
}

.grey-container {
    margin-top: 1rem;
    padding: 1rem;
    background: #f9fafb;
    width: 145%;
    /* Gray/200 (borders) */

    border: 1px solid #eaecf0;
    /* shadow/base */

    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.01), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
}

.modal-add-equipment label {
    font-weight: 400;
}

.add-equipment-footer {
    display: flex;
    width: '100%';
    width: 100%;
    gap: 0.25rem;
    justify-content: space-between;
}

.equipment-name-cell {
    cursor: pointer;
}

.equipment-page>.page-title {
    margin-bottom: 1rem;
}

.equipment-page .data-table-widget-wrapper {
    min-height: 37.5rem;
}

.equipment-page .heading-style {
    margin-left: 0px;
}

.tab-switch {
    border: none;
    background-color: #ffffff;
    width: 100%;
}

.white-container {
    clear: both;
    margin-top: 1rem;
    padding: 1rem;
    width: 145%;
    background: #ffffff;
    /* Gray/200 (borders) */

    border: 1px solid #eaecf0;
    /* shadow/base */

    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.01), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
}

.pic-container {
    background: #ffffff;
    /* Gray/200 (borders) */

    border: 1px solid #eaecf0;
    /* shadow/sm */

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
}

.singleline-toggle-switch {
    /* float: right; */
    height: 10px;
    width: 10px;
}

.equip-type-style {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #3c6df5;
}

.singleline-box-style {
    /* float: right; */
    background: #ffffff;
    border: 1px solid #d9dde2;
    box-shadow: 0px 1px 0px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    width: 350px;
    height: 34px;
}

.card-alignment {
    margin-left: 20px !important;
}

.grid-style-1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.grid-style-2 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
}

.grid-style-3 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
}

.grid-style-4 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.grid-style-five {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1px;
}

.trash-button-style {
    background: #d92d20;
}

.operate-hour-style {
    display: inline-block;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin: 12px;
}

.time-style {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.time-button-style {
    width: 95px;
    height: 34px;
    border: 1px solid #d9dde2;
    box-sizing: border-box;
    box-shadow: 0px 1px 0px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
}

.week-day-style {
    border-radius: 4px;
    width: 50px;
    height: 22px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3px 8px 4px;
    gap: 10px;
    background: #f2f4f7;
    border-radius: 4px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #000000;
}

.spacing {
    margin: 10px;
}

.table-styling {
    background: #ffffff;
    border: 2px solid #eaecf0;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
}

.grey-out {
    color: rgb(179, 176, 176);
}

.icon-bg-styling {
    background: #12b76a;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    padding-top: 3px;
}

.icon-bg-styling-slash {
    background: rgb(240, 28, 28);
    height: 25px;
    width: 25px;
    border-radius: 50%;
    padding-top: 3px;
}

.icon-styling {
    color: white;
    margin-left: 4px;
}

.panel-name {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #2955e7;
}

.gateway-content-style {
    margin-left: 40px;
    font-weight: 700;
}

.layout-container {
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    padding: 10px;
    /* border: solid rgb(159, 159, 159); */
    border-width: 1px;
    border-radius: 5px;
    box-shadow: 0px 0px 1px black;
    /* overflow-x: scroll; */
}

.new-scroll {
    width: auto;
    overflow-x: scroll;
}

.container-heading {
    height: 40px;
    border-width: 1px;
    display: flex;
    align-items: center;
    background: #f3f4f7;
    padding-left: 10px;
    margin-bottom: 10px;
    font-weight: 700;
}

.container-content-group {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
}

.hover-image-delete:hover {
    background: #f3f4f7;
    background-image: url('../../assets/icon/pencil.png');
}

.container-single-content {
    padding-top: 5px;
    height: 40px;
    align-content: center;
    font-weight: 500;
}

.equip-head-style {
    font-size: x-small;
    font-weight: 800;
}

.modal-container-style {
    max-width: 75.375rem !important;
    max-height: 48.4375rem !important;
    height: 48.4375rem !important;

}

.modal-tabs-style {
    font-weight: 600;
    /* text-decoration: underline;
    text-decoration-color: #2955e7; */
}

.tab-styling {
    border-bottom: 2px solid rgb(80, 92, 255);
    padding-bottom: 3px;
    color: #2955e7;
    font-weight: bold;
}

.modal-right-card {
    display: grid;
    grid-template-columns: 2fr 1fr;
}

.modal-right-pic {
    width: auto;
    height: 200px;
    background-color: #c8c8c8;
}

.modal-right-card-title {
    font-size: medium;
    font-weight: 700;
    display: flex;
    align-items: center;
}

.modal-right-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.grid-style-5 {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    gap: 10px;
}

.panel-container-style {
    background: #fafbfc;
    width: auto;
    height: auto;
    border: 1px solid #eaecf0;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.01), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    padding-bottom: 10vh;
}

.breaker-style {
    /* display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr; */
    display: flex;
    flex-direction: row;
    width: 300px;
    height: 48px;
    background: #f9fafb;
    border: 2px solid #eaecf0;
    border-radius: 4px;
}

.all-breakers-style {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    width: 376px;
    height: 50px;
    background: #f9fafb;
    border: 1px solid #eaecf0;
    /* box-sizing: border-box; */
    border-radius: 4px;
}

.breaker-content-middle {
    height: 100%;
    /* width: 50%; */
    margin-left: 25px;
    display: flex !important;
    text-align: center !important;
    justify-content: center !important;
    align-items: center !important;
}

.edit-icon-bg-styling {
    background: #98a2b3;
    border-radius: 5px;
    height: 23px;
    width: 23px !important;
    padding: 3px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dot-status {
    display: flex;
    justify-content: center !important;
    width: 8px;
    height: 8px;
    border-radius: 20px;
    background-color: #66d6bc;
}

.breaker-content {
    display: flex;
    flex-direction: column;
    font-size: small;
    font-weight: 500;
}

.breaker-index {
    display: flex;
    justify-content: center;
}

.breaker-container {
    text-align: center;
}

.grid-style-6 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0px;
}

.sub-breaker-style {
    display: flex;
    flex-direction: row;
    width: 350px !important;
    height: 50px;
    background: #f9fafb;
    border: 2px solid #eaecf0;
    border-radius: 4px;
}

.breaker-container :hover {
    background: white;
    box-shadow: 0px 0px 2px rgb(163, 161, 161);
}

.edit-btn-styling {
    color: #667085;
    font-weight: 600;
    font-size: 13px;
}

.breaker-equip-name {
    font-weight: 700;
    font-size: 12px;
    color: #1d2939;
    white-space: nowrap;
    width: 5.5vw;
    overflow: hidden;
    text-overflow: ellipsis;
}

.breaker-equip-name:hover {
    overflow: visible;
}

.time-picker-style {
    width: 95px;
    height: 34px;
    padding: 7px 10px;
    background: #ffffff;
    border: 1px solid #d9dde2;
    box-sizing: border-box;
    box-shadow: 0px 1px 0px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
}

.time-picker-style-disabled {
    background: #f9fafb;
    width: 95px;
    height: 34px;
    border: 1px solid #d9dde2;
    box-sizing: border-box;
    padding: 7px 10px;
    box-shadow: 0px 1px 0px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
}

.time-picker-text-style-disabled {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #667085;
}

.weekday-style {
    width: 50px;
    height: 22px;
    padding: 3px 8px 4px;
    background: #f2f4f7;
    border-radius: 4px;
}

.real-power-style {
    color: #1d2939;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
}

.buildings-name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #2955e7;
    line-height: 17px;
    color: #3c6df5;
}

.label-styling {
    background: #f2f4f7 !important;
    padding: 2px 4px !important;
    color: #98a2b3 !important;
    font-weight: 600 !important;
    font-size: 11px !important;
    line-height: 12px !important;
}

.mouse-pointer {
    cursor: pointer;
}

.cancel-btn-style {
    border: 1px solid #d9dde2 !important;
    box-shadow: 0px 1px 0px rgba(16, 24, 40, 0.05) !important;
    border-radius: 6px !important;
}

.emailId-style {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #667085;
    align-self: stretch;
    margin: 4px 0px;
}

.user-role-textbox {
    width: 13.0625rem !important;
}

.view-role-style {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #3c6df5;
}

.user-role-style {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.admin-text-style {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
}

.full-access-style {
    display: flex;
    flex-direction: row;
}

.full-access-header {
    /* display: inline-block; */
    display: inline-flex;
    /* flex-direction: row; */
}

.full-access-header1 {
    display: flex;
    flex-direction: row;
    float: right !important;
}

.full-access-font {
    color: #344054;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
}

.custom-active-btn {
    padding: 7px 12px;
    background: #f9fafb;
    border: 1px solid #d0d5dd;
    border-radius: 6px 0px 0px 6px;
    margin: 0px 0px;
}

.custom-inactive-btn {
    padding: 7px 12px;
    background: #ffffff;
    border: 1px solid #d0d5dd;
    border-radius: 0px 6px 6px 0px;
    margin: 0px 0px;
}

.building-heading-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: stretch;
}

.buildings-cancel-style {
    background: #ffffff !important;
    border: 1px solid #d9dde2 !important;
    box-shadow: 0px 1px 0px rgba(16, 24, 40, 0.05) !important;
    border-radius: 6px !important;
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    color: black !important;
}

.buildings-save-style {
    background: #2955e7 !important;
    border: 1px solid #2142d4 !important;
    box-shadow: 0px 1px 0px rgba(16, 24, 40, 0.05) !important;
    border-radius: 6px !important;
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    color: #ffffff !important;
}

.building-section-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #475467;
}

.building-content-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #1d2939;
}

.building-content-subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #475467;
}

.loader-center-style {
    min-height: 35vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-container {
    display: flex;
    justify-content: space-between;
}

.chart-border-style {
    background: #ffffff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.01), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    margin-top: 15px;
    padding: 1rem;
}

.triple-dot-style {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px;
    gap: 8px;
    width: 34px;
    height: 34px;
    background: #ffffff;
    border: 1px solid #d9dde2;
    box-shadow: 0px 1px 0px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
}

.delete-btn-style {
    color: #d92d20;
}

.disable-delete-btn-style {
    color: #ad716c;
}

.multi-select-tags > div {
    border-radius: 0.375rem !important;
    border-color: #7c879c !important;
}