@use '../../assets/scss/colors' as colors;
@use '../assets/scss/helpers' as *;

.toggles {
    &-wrapper {
        display: flex;
        gap: 0.5rem;
        align-items: self-start;

        &.text-alignment-left {
            flex-direction: row-reverse;
            justify-content: flex-end;
            text-align: right;
        }

        .switch {
            position: relative;
            display: block;
        }

        .switch input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: colors.$primary-gray-250;
            -webkit-transition: 0.4s;
            transition: 0.4s;
        }

        /* Rounded sliders */
        .slider.round {
            border-radius: 2.125rem;
        }

        .slider.round:before {
            border-radius: 50%;
        }

        .slider:before {
            position: absolute;
            content: '';
            background-color: colors.$base-white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
        }

        input:checked + .slider {
            background-color: colors.$dataviz-blue-700;
        }

        &.sm {
            .switch {
                height: 1.25rem;
                width: 2.25rem;
            }

            .slider:before {
                height: 1rem;
                width: 1rem;
                left: 0.125rem;
                bottom: 0.125rem;
            }

            input:checked + .slider:before {
                transform: translateX(1rem);
            }
        }
    }
}
