.panel-breakers-wrapper {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    padding: 0 1.625rem;
    position: relative;
    max-width: 53.5rem;
    margin: auto;

    .breaker-wrapper {
        width: 24.125rem;
    }

    &.is-one-column {
        width: 27.375rem;
        margin: auto;
    }
}
