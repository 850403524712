.layout-location-selection-menu-list {
    --default-bg: #{$primary-indigo-25};
    padding: 0.4375rem 0.75rem;
    width: 17.1875rem;
    min-height: 2.125rem;
    background: $base-white;

    svg {
        display: block;
    }

    &-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 0.3125rem;
    }

    &:not(.not-editable) &-action-buttons {
        &::before {
            position: absolute;
            content: '';
            top: 0;
            bottom: 0;
            left: -3.5rem;
            width: 1.875rem;
            background: linear-gradient(90deg, rgba(245, 245, 248, 0) 0%, var(--default-bg) 80%);
        }
    }

    &-action-buttons {
        display: flex;
        gap: 0.5625rem;
        align-items: center;
        margin-top: -0.0625rem;
        position: relative;
        align-self: stretch;

        .badge-wrapper {
            padding: 0.0625rem 0.375rem;

            .typography-wrapper {
                font-size: 0.625rem;
                color: $base-black;
            }
        }

        &-edit {
            position: absolute;
            left: -1.6875rem;
            height: 100%;
            background: var(--default-bg) !important;
            padding-right: 0.5rem !important;

            svg {
                path {
                    fill: $primary-gray-450;
                }
            }

            &:hover {
                svg {
                    path {
                        fill: $primary-indigo-600;
                    }
                }
            }
        }
    }

    &-click {
        width: 1.25rem;
        height: 1.25rem;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            height: 0.75rem;

            path {
                fill: $primary-gray-450;
            }
        }
    }

    &:not(:hover) &-action-buttons {
        &:before {
            display: none;
        }

        &-edit {
            display: none;
        }
    }

    &:hover {
        background: var(--default-bg);

        .badge-wrapper {
            background: $primary-gray-200;
        }
    }

    &.is-active {
        --default-bg: #{$primary-indigo-700};
        background: $primary-indigo-600;

        .layout-location-selection-menu-list-action-buttons-edit {
            svg {
                path {
                    fill: $primary-indigo-100;
                }
            }

            &:hover {
                svg {
                    path {
                        fill: $base-white;
                    }
                }
            }
        }

        .badge-wrapper {
            background: $primary-indigo-200;

            .typography-wrapper {
                color: $primary-indigo-800;
            }
        }

        .layout-location-selection-menu-list-click {
            svg {
                path {
                    fill: $base-white;
                }
            }
        }

        .layout-location-selection-menu-list-title {
            color: $base-white !important;
        }

        &:hover {
            background: $primary-indigo-700;
        }
    }
}
