@use '../../assets/scss/colors' as colors;

.trends {
    &-badge {
        font-style: normal;
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 1.125rem;
        height: 1.25rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        align-self: flex-end;
        padding: 0 0.25rem;
        background-color: colors.$success-100;
        border-radius: 0.375rem;
        color: colors.$success-600;

        &.downward-trend {
            background-color: colors.$success-100;
            color: colors.$success-800;
            svg {
                path {
                    fill: colors.$success-800;
                }
            }
        }

        &.upward-trend {
            background-color: colors.$error-100;
            color: colors.$error-700;
            svg {
                path {
                    fill: colors.$error-700;
                }
            }
        }

        &.neutral-trend {
            background-color: colors.$primary-gray-150;
            color: colors.$primary-gray-550;
            svg {
                path {
                    fill: colors.$primary-gray-550;
                }
            }
        }

        svg {
            margin-right: 0.25rem;
        }
    }
}
