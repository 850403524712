@use '../../../assets/scss/colors' as colors;
@use '../../assets/scss/helpers' as *;

.react-select-wrapper {
    font-weight: 500;
    font-size: 0.875rem;
    min-width: 8.125rem;

    .horizontal-separator {
        border-bottom: 0.0625rem solid colors.$primary-gray-200;
        padding: 0 0.875rem;
        width: calc(100% - 1.75rem);
        margin: auto;
    }

    [class*='-control'] {
        border-radius: 0.375rem;
    }

    [class*='-control'],
    [class*='-control']:hover {
        min-height: 2.25rem;
        border: 0.0625rem solid colors.$primary-gray-450;
        box-shadow: none;
        cursor: pointer;
    }
    [class*='-control'].is-open {
        box-shadow: 0 0.0625rem 0.125rem rgba(16, 24, 40, 0.05), 0 0 0 0.25rem #dbe8fe;
        border-color: colors.$primary-indigo-300;
    }
    [class*='indicatorSeparator'] {
        display: none;
    }
    [class*='-indicatorContainer'] {
        padding: 0 0.75rem 0 0.5rem;

        .is-open {
            @extend .rotate-180;
        }

        svg {
            transition: 0.4s all;
            path {
                fill: colors.$primary-gray-500;
            }
        }
    }
    [class*='-ValueContainer'] {
        padding: 0 0 0 0.625rem;

        .multi-single-value {
            height: 1.75rem;
            background: colors.$primary-gray-100;
            border: 0.0625rem solid colors.$primary-gray-200;
            border-radius: 0.25rem;
            display: flex;
            align-items: center;
            padding: 0.25rem 0.375rem;

            .react-select-option-icon {
                max-width: 0.75rem;
            }

            &-close-btn {
                background: none;
                padding: 0;
                margin: 0;
                border: 0;
                outline: none;
                margin-left: 0.625rem;
                margin-right: 0.125rem;

                path {
                    fill: colors.$primary-gray-450;
                }

                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }
    [class*='-singleValue'] {
        margin: 0;
        height: 1.25rem;
        & > div {
            max-height: 100%;
            .react-select-option-icon {
                max-height: 1.25rem;
            }
        }
    }
    [class*='-option'] {
        text-align: left;
    }
    [class*='-placeholder'] {
        margin: 0;

        & > div {
            color: colors.$primary-gray-350 !important;
            font-weight: 500 !important;
        }
    }
    [class*='-menu'] {
        border: 0.0625rem solid colors.$primary-gray-200;
        box-shadow: 0 0.25rem 0.375rem -0.0625rem rgba(16, 24, 40, 0.02),
            0 0.125rem 0.25rem -0.0625rem rgba(16, 24, 40, 0.06);
        border-radius: 0.375rem;
        min-width: 12.5rem;

        .search-field-wrapper {
            padding: 0.625rem 0.875rem;
            padding-bottom: 0;

            input {
                &:focus {
                    box-shadow: none;
                    border-color: colors.$primary-gray-200;
                    cursor: text;
                }
            }
        }
    }

    .react-select-option {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        padding: 0.625rem 0.875rem;
        color: colors.$primary-gray-900;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &--is-focused,
        &--is-selected {
            background: colors.$primary-gray-50 !important;
            &.selectAllOption {
                background: none !important;
            }
        }
        &--is-focused {
            cursor: pointer;
            .typography-wrapper {
                color: colors.$primary-gray-700 !important;
            }
        }
        &--is-disabled {
            background: none;

            .typography-wrapper {
                color: colors.$primary-gray-250 !important;
            }

            &.react-select-option--is-selected {
                background: colors.$primary-gray-25 !important;
                svg path {
                    fill: colors.$primary-gray-250;
                }
            }
        }
    }

    .react-select-option-icon {
        margin-right: 0.5rem;
    }
    .react-select-option-support-text {
        margin-left: 0.5rem;
        color: colors.$primary-gray-550;
    }
    .react-select-icon-chart-wrapper {
        &:not(.hideTick) {
            display: grid;
            grid-template-columns: auto 0.9375rem;
            gap: 0.625rem;
        }
    }

    &.is-error {
        [class*='-control'],
        [class*='-control']:hover {
            border-color: colors.$error-500;
        }
        [class*='-control'].is-open {
            box-shadow: 0 0.0625rem 0.125rem rgba(16, 24, 40, 0.05), 0 0 0 0.25rem colors.$error-100;
        }
    }
}

// Created seperate styling because SCSS for multi-select not loading when code deployed to server
.multi-select-value {
    height: 1.75rem;
    background: colors.$primary-gray-100;
    border: 0.0625rem solid colors.$primary-gray-200;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    padding: 0.25rem 0.375rem;

    .react-select-option-icon {
        max-width: 0.75rem;
    }
}

.multi-select-close-btn {
    background: none;
    padding: 0;
    margin: 0;
    border: 0;
    outline: none;
    margin-left: 0.625rem;
    margin-right: 0.125rem;

    path {
        fill: colors.$primary-gray-450;
    }

    &:hover {
        opacity: 0.7;
    }
}
